let apiHost = 'https://www.paypros.in/api'
let nginxHost = 'https://www.paypros.in/download'

export default {
    title: '商户管理后台',
    homeName: 'index',
    baseUrl: {
        api: apiHost,
        static: nginxHost
    }
}