<template>
  <div class="main-view">
    <SideMenu>

      <!-- Settlement card using Element UI el-card component -->
      <el-card :gutter="20" style="height: 99%;" shadow="hover">
        <template #header>
          <div class="card-header">
            <span>Settlement</span>
          </div>
        </template>

        <!-- Settlement data filter form using Element UI el-form component -->
        <el-form ref="listDataForm" :model="listData.filter" >
          <el-row style="font-size: 12px;">
            <!-- Creation time filter -->
            <el-form-item >
              <el-date-picker v-model="listData.filter.startTime" type="date" placeholder="Start Time" style="font-size: 12px; width: 180px"></el-date-picker>
              &nbsp - &nbsp
              <el-date-picker v-model="listData.filter.endTime" type="date" placeholder="End Time" style="font-size: 12px; width: 180px"></el-date-picker>
            </el-form-item>
            &nbsp &nbsp
            <!-- Search, Reset, and Export Report buttons -->
            <el-button type="primary" style="background-color: #0071C6; border: 1px solid #cecccc" @click="search" :disabled="listData.loading">
              <el-icon><Search/></el-icon> Search
            </el-button>
            <el-button type="primary" style="background-color: #ffffff; color:#000000; border: 1px solid #cecccc" @click="reset" :disabled="listData.loading">
              <el-icon><Refresh /></el-icon> Reset
            </el-button>
<!--            <el-button type="primary" style="background-color: #ffffff; color:#000000; border: 1px solid #cecccc" @click="exportExcel" :disabled="listData.loading">-->
<!--              <el-icon><Download/></el-icon> Export-->
<!--            </el-button>-->
            <el-button type="primary"
                       style="background-color: #ffffff; color:#000000; border: 1px solid #cecccc"
                       @click="exportCSV" :disabled="listData.loading">
              <el-icon><Download/></el-icon> Export CSV
            </el-button>
            <!-- Settle Amount -->

            <el-form-item label="Total Settle Amount: " style="font-size: 12px; margin-left: auto;">
              <span>{{ listData.filter.totalSettleAmount }}</span>
            </el-form-item>
          </el-row>

          <div style="font-size: 12px; text-align: left" v-show="isShow">
            <el-progress :percentage="percent" style="width: 350px;"/>
            &nbsp;&nbsp;&nbsp;&nbsp;
            Current Download Count：
            <label>{{ count }}</label>
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <label>{{ csvTotal }}</label>
          </div>


          <div style="font-size: 12px; text-align: left" v-show="orderIsShow">
            <el-progress :percentage="orderPercent" style="width: 350px;"/>
            &nbsp;&nbsp;&nbsp;&nbsp;
            Current Download Count：
            <label>{{ orderCount }}</label>
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <label>{{ orderCsvTotal }}</label>
          </div>
          <br/>
        </el-form>


        <!-- Settlement data table -->
        <el-table ref="listTable" v-loading="listData.loading" :columns="listData.columns" :data="listData.list" border class="table-container">
          <template #default="{ $index, row }">
            <el-table-column type="index" :index="getIndex" fixed prop="serialNo" label="Serial No" width="150"/>
            <el-table-column prop="settleDate" label="Settlement Date" width="200" :formatter="formatDate" />
            <el-table-column prop="orderCount" label="Order Count" width="200"/>
            <el-table-column prop="orderAmount" label="Order Amount" width="160"/>
            <el-table-column prop="orderFee" label="Order Fee" width="160"/>
            <el-table-column prop="settleAmount" label="Settlement Amount" width="160"/>
            <el-table-column prop="status" label="Settlement Status" width="150">
              <template #default="{ row }">
                <div v-if="row.status === 0" >Processing</div>
                <div v-else-if="row.status === 1" >Finished</div>
                <div v-else-if="row.status === 2" >Postponed</div>
                <div v-else-if="row.status === 3" >Awaiting</div>
                <div v-else-if="row.status === 4" >Delayed Released</div>
                <div v-else-if="row.status === 5" >Deduction</div>
                <div v-else-if="row.status === 6" >Repayment</div>
                <div v-else-if="row.status === 7" >Refund</div>
                <div v-else>Unknown</div>
              </template>
            </el-table-column>

            <!-- Table column: Management button, click to show the order list of the settlement record -->
            <el-table-column prop="btn" label="Manager">
              <template #default="{ row }">
                <el-button v-if="row.status === 1" type="primary" style="background-color: #0071C6; border: 1px solid #cecccc" @click="orderList(row.id)">Order List</el-button>
<!--                <el-button v-if="row.status === 1" type="primary" style="background-color: #ffffff; color:#000000; border: 1px solid #cecccc" @click="orderExportExcel(row.id)" :disabled="listData.loading">-->
<!--                  <el-icon><Download /></el-icon> Export-->
<!--                </el-button>-->
                <el-button v-if="row.status === 1" type="primary"
                           style="background-color: #ffffff; color:#000000; border: 1px solid #cecccc"
                           @click="exportOrderCSV(row.id)" :disabled="orderListData.loading">
                  <el-icon><Download/></el-icon> Export CSV
                </el-button>
              </template>
            </el-table-column>
          </template>
        </el-table>

        <div class="pagination-container">
          <el-pagination
              v-if="listData.total > 0"
              v-model:current-page="listData.filter.page"
              v-model:page-size="listData.filter.rows"
              :page-sizes="[10, 20, 30, 40]"
              layout="total, prev, pager, next, sizes"
              :total="listData.total"
              @current-change="changePage"
              @size-change="changepageSize"
              style="font-size: 12px; position: absolute; bottom: 50px; "
          />
        </div>

        <!-- Settlement order list -->
        <el-dialog v-model="orderListData.state" :title="orderListData.title" :closable="false" width="1015">
          <el-table ref="listOrderTable" v-loading="orderListData.loading" :data="orderListData.list" border class="table-container">
            <el-table-column prop="orderNo" label="mchTxnId" width="200"></el-table-column>
            <el-table-column prop="sysOrderNo" label="refId" width="200"></el-table-column>
            <el-table-column prop="amount" label="orderAmount" width="105"></el-table-column>
            <el-table-column prop="cost" label="Fee" width="85"></el-table-column>
            <el-table-column prop="orderStatus" label="txnStatus" width="85">
              <template #default="{ row }">
                <div v-if="row.orderStatus === 1" >Approved</div>
                <div v-else-if="row.orderStatus === 2" >Rejected</div>
                <div v-else-if="row.orderStatus === 3" >Refunded</div>
                <div v-else>Unpaid</div>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="Creation Time" width="160" :formatter="formatDate"></el-table-column>
            <el-table-column prop="finishTime" label="Updated Time" width="160" :formatter="formatDate"></el-table-column>
          </el-table>

          <div class="pagination-container">
            <el-pagination
                v-if="orderListData.total > 0"
                v-model:current-page="orderListData.filter.page"
                v-model:page-size="orderListData.filter.rows"
                :page-sizes="[10, 20, 30, 40]"
                layout="total, prev, pager, next, sizes"
                :total="orderListData.total"
                @current-change="changeorderPage"
                @size-change="changeorderpageSize"
                style="font-size: 12px; position: absolute; bottom: 10px;"
            />
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" style="background-color: #0071C6; border: 1px solid #cecccc; position: absolute; bottom: 10px; margin-right: 0" @click="cancel">
              <el-icon><Close /></el-icon> Close
            </el-button>
          </span>
        </el-dialog>
      </el-card>
    </SideMenu>
  </div>
</template>

<script>
import SideMenu from "@/components/SideMenu";
import { mapActions } from 'vuex';
import { getMerSettleRecordList, getOrderList, merSettleRecordExcelDown, merSettleRecordOrderExcelDown, settlementCsvDown, settlementCsvProgress, settlementOrderCsvDown } from "@/api/SettlementApi";
import store from "@/store/UserStore";
import config from "@/config/index";

export default {
  name: "SettlementView",
  components: {
    SideMenu
  },


  data() {
    return {
      listData: {
        loading: false,
        filter: {
          page: 1,
          rows: 10,
          orderAmount: '',
          merNo: '',
          settleAmount: '',
          orderCount: '',
          status: '',
          startTime: '',
          endTime: '',
          logonToken: store.state.token,
          loginUserName: store.state.loginName,
        },
        list: [],
        columns: [
          {
            key: 'serialNo',
            align: 'center',
            render: (h, params) => {
              const index = (this.listData.filter.page - 1) * this.listData.filter.rows + params.index + 1;
              return h('div', index);
            },
          },
          {
            key: 'settleDate',
          },
          {
            key: 'orderCount',
          },
          {
            key: 'orderAmount',
          },
          {
            key: 'orderFee',
          },
          {
            key: 'settleAmount',
          },
          {
            key: 'status',
          },
          {
            key: 'btn',
            slot: 'btn',
          }
        ]
      },
      orderListData: {
        loading: false,
        state: false,
        title: 'Order List',
        filter: {
          page: 1,
          rows: 10,
          merbalanceSettleId: '',
          merSettleRecordId: '',
          logonToken: store.state.token,
          loginUserName: store.state.loginName,
        },
        list: [],
        columns: [
          {
            key: 'orderNo',
            width: 200
          },
          {
            key: 'sysOrderNo',
            width: 200
          },
          {
            key: 'amount',
            width: 85
          },
          {
            key: 'cost',
            width: 85
          },
          {
            key: 'orderStatus',
            width: 85,
          },
          {
            key: 'createTime',
            width: 150
          },
          {
            key: 'finishTime',
            width: 150
          }
        ]
      },
      summaryData: {
        columns: [],
        list: []
      },
      merSettleRecordId: '',
      tableHeight: 520,
      firstRow: '',
      total: 0,
      intervalId: null,
      isShow: false,
      percent: 0,
      count: 0,
      csvTotal: 0,
      orderIsShow: false,
      orderPercent: 0,
      orderCount: 0,
      orderTotal: 0,
      orderCsvTotal: 0,
    }
  },

  methods: {
    ...mapActions([
      'userLogoutAction'
    ]),
    //export the record in csv file
    exportCSV() {
      let tempFilter = this.listData.filter;

      //generate csvToken for export and store it
      settlementCsvDown(tempFilter).then(async res => {
        if (res.data != null) {
          let csvToken = res.data
          this.dataRefresh(csvToken)
        }
      }).catch((e) => {
        // console.log("exportCsv, e:" + e)
      })
    },

    //export the record in csv file
    exportOrderCSV(id) {
      this.orderListData.loading = true;
      let tempFilter = this.orderListData.filter;

      const merSettleRecordId = id;

      tempFilter.merSettleRecordId = merSettleRecordId;

      //generate csvToken for export and store it
      settlementOrderCsvDown(tempFilter).then(async res => {
        if (res.data != null) {
          let csvToken = res.data
          this.dataRefresh(csvToken)
        }
      }).catch((e) => {
        // console.log("exportCsv, e:" + e)
      })
    },


    //check csv download progress with csvToken generated in payDFCsvDown
    dataRefresh(csvToken) {
      // The timer is in progress, exit the function
      if (this.intervalId != null) {
        return
      }
      // The timer is empty and operates
      this.intervalId = setInterval(() => {
        let params = {logonToken: this.listData.filter.logonToken, csvToken: csvToken}
        settlementCsvProgress(params).then(async res => {
          this.isShow = true // show progress bar
          if (res.code == 0) {
            let data = res.data
            if (data != null) {
              // if still downloading
              if (this.percent < 100) {
                this.percent = data.percent
                this.csvTotal = data.total
                this.count = data.count
              } else { // download completed
                this.clear()
                this.isShow = false // hide progress bar
                this.percent = data.percent
                //direct to this urls in new tab
                // let urls= cfg.baseUrl.api + "/file/" + csvToken + `.csv`
                let urls = `${config.baseUrl.static}/` + csvToken + `.csv`
                window.open(urls, '_blank')
              }
            }
            // this.clear()
          }
        }).catch((e) => {
          // console.log("e:" + e)
        })
      }, 1000) // check the progress every 1 second
    },

    clear() {
      clearInterval(this.intervalId) // Clear timer
      this.intervalId = null // set as null
      this.search()
    },

    formatDate(row, column) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const dateString = row[column.property];
      const date = new Date(dateString);
      if (isNaN(date)) {
        return '';
      }

      const day = String(date.getDate()).padStart(2, '0');
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      // const hours = String(date.getHours()).padStart(2, '0');
      // const minutes = String(date.getMinutes()).padStart(2, '0');
      // const seconds = String(date.getSeconds()).padStart(2, '0');

      // return `${day}${month}${year} ${hours}:${minutes}:${seconds}`;
      return `${day} ${month} ${year} `;
    },


    // Get the index in the table based on the current page and index
    getIndex(index) {
      return (this.listData.filter.page - 1) * this.listData.filter.rows + index + 1;
    },

    // Search
    search() {
      this.listData.filter.page = 1;
      this.listData.filter.status = this.listData.filter.status.toString();
      this.loadList();
    },

    // Reset
    reset() {
      this.listData.filter.rows = 10;
      this.listData.filter.page = 1;
      this.listData.filter.status = '';
      this.listData.filter.startTime = '';
      this.listData.filter.endTime = '';
      this.$refs['listDataForm'].resetFields();
      this.listData.list = []; // Clear the table data
      this.listData.total = 0; // Reset the total count
      this.listData.filter.totalSettleAmount = 0;
      this.loadList();
    },

    // Close the order list
    cancel() {
      this.orderListData.filter.rows = 10;
      this.orderListData.filter.page = 1;
      this.orderListData.filter.merbalanceSettleId = '';
      this.orderListData.filter.merSettleRecordId = '';
      this.merSettleRecordId = '';
      this.orderListData.state = false;
    },

    // Load settlement data list, call backend API to get data and update table display
    loadList() {
      this.listData.loading = true;
      let tempFilter = this.listData.filter;

      if (tempFilter.startTime) {
        const parsedStartTime = new Date(tempFilter.startTime);
        tempFilter.startTime = this.formatDateToAPIFormat(parsedStartTime);
      }

      if (tempFilter.endTime) {
        const parsedEndTime = new Date(tempFilter.endTime);
        tempFilter.endTime = this.formatDateToAPIFormat(parsedEndTime);
      }

      // API call to get merchant settlement records
      getMerSettleRecordList(tempFilter).then(res => {
        if (res.code == 0) {
          this.listData.loading = false;

          // Extract data from the API response
          let tempData = res.data;

          // Check if the response data contains the 'rows' property and 'rows' is an array
          if (tempData && Array.isArray(tempData.rows)) {
            // If 'rows' property exists and is an array, update table data and total count
            this.listData.list = tempData.rows;
            this.listData.total = tempData.total;

            // Calculate the page size, default is 10
            const pageSize = this.listData.filter.rows;

            // Calculate the current page, default is 1
            const currentPage = this.listData.filter.page;

            // Calculate the start and end index of data on the current page
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = currentPage * pageSize;

            // Split data into the current page's data
            // Use the slice method to extract the data of the current page from the tempData.rows array
            this.listData.list = tempData.rows.slice(startIndex, endIndex);

            // Calculate and set total settle amount
            let totalSettleAmount = 0;
            for (const row of tempData.rows) {
              totalSettleAmount += row.settleAmount;
            }
            this.listData.filter.totalSettleAmount = totalSettleAmount;

          } else {
            // If 'rows' property does not exist or is not an array, clear the table data and set the total count to 0
            this.listData.list = [];
            this.listData.total = 0;
          }

        } else if (res.code == 18) {
          this.userLogoutAction().then(() => {
            this.$router.push('/login');
          });
          this.$message.error(res.msg);
        }
      }).catch(err => {
        // console.log(err);
      });
    },

    // Order list
    orderList(id) {
      this.merSettleRecordId = id;
      this.orderListData.state = true;
      this.orderListData.loading = true;
      let tempFilter = this.orderListData.filter;
      tempFilter.merSettleRecordId = id;

      getOrderList(tempFilter)
          .then(res => {
            if (res.code == 0) {
              this.orderListData.loading = false;
              // Extract data from the API response
              let tempData = res.data;

              // Check if the response data contains the 'rows' property and 'rows' is an array
              if (tempData && Array.isArray(tempData.rows)) {
                // If 'rows' property exists and is an array, update table data and total count
                this.orderListData.list = tempData.rows;
                this.orderListData.total = tempData.total;

                // Calculate the page size
                const pageSize = this.orderListData.filter.rows;

                // Calculate the current page
                const currentPage = this.orderListData.filter.page;

                // Calculate the start and end index of data on the current page
                const startIndex = (currentPage - 1) * pageSize;
                const endIndex = currentPage * pageSize;

                // Split data into the current page's data
                this.orderListData.list = tempData.rows.slice(startIndex, endIndex);

              } else {
                // If 'rows' property does not exist or is not an array, clear the table data and set the total count to 0
                this.orderListData.list = [];
                this.orderListData.total = 0;
              }
              this.getData(tempData);
            } else if (res.code == 18) {
              this.userLogoutAction().then(() => {
                this.$router.push('/login');
              });
              this.$message.error(res.msg);
            } else {
              this.orderListData.loading = false;
              this.$message.error(res.msg);
            }
          })
          .catch(err => {
            this.orderListData.loading = false;
            // console.error(err); // Log the error object to see its structure
            this.$message.error('An error occurred while fetching the order list: ' + err.message); // Use the error message from the 'message' property
          });
    },

    // Export table to Excel
    exportExcel() {
      this.listData.loading = true;
      let tempFilter = this.listData.filter;

      merSettleRecordExcelDown(tempFilter)
          .then(res => {
            this.listData.loading = false;
            const blob = new Blob([res], {type: 'application/vnd.ms-excel'});
            let filename = new Date().getTime() + '.xls';
            const elink = document.createElement('a');
            elink.download = filename;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          })
          .catch(err => {
            this.orderListData.loading = false;
            this.$message.error('Export failed');
          });
    },


    orderExportExcel(e) {
      // console.log('e:', e);
      this.orderListData.loading = true;
      let tempFilter = this.orderListData.filter;

      const merSettleRecordId = e;

      tempFilter.merSettleRecordId = merSettleRecordId;
      merSettleRecordOrderExcelDown(tempFilter)
          .then(res => {
            this.orderListData.loading = false;
            const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
            let filename = new Date().getTime() + '.xls';
            const elink = document.createElement('a');
            elink.download = filename;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          })
          .catch(err => {
            this.orderListData.loading = false;
            this.$message.error('Export failed');
          });
    },

    // Next page
    changePage(page) {
      this.listData.filter.page = page;
      this.loadList();
    },

    changepageSize(pageSize) {
      this.listData.filter.rows = pageSize;
      this.loadList();
    },

    changeorderPage(page) {
      this.orderListData.filter.page = page;
      this.orderList(this.merSettleRecordId);
    },

    changeorderpageSize(pageSize) {
      this.orderListData.filter.rows = pageSize;
      this.orderList(this.merSettleRecordId);
    },

    formatDateToAPIFormat(date) {
      // Returns the date in 'yyyy-MM-dd hh:mm:ss' format
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    },

    // Handle the order list data obtained from the backend
    getData(data) {
      let tempFilter = this.listData.filter;
      this.totalCount = data.total;
      if (data.rows.length > 0) {
        // Get the ID of the first row
        tempFilter.firstOrderId = data.rows[0].id;
        // Get the ID of the last row
        tempFilter.lastOrderId = data.rows[data.rows.length - 1].id;
        // Current page number
        tempFilter.firstPage = data.rows[0].page;
        // Current page size
        this.firstRow = data.rows[0].rows;
      }
    },
  },

  created() {
    this.loadList();
  }
}
</script>

<style scoped>
.el-button {
  background-color: #2389da;
  color: white;
  border: none;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

}

.el-button:hover {
  background-color: #5abcd8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

body,
.el-table th,
.el-table td,
.el-input{
  font-size: 12px;
}

.card-header {
  text-align: center;
  font-weight: bold;
}

.dialog-footer {
  display: flex;
  justify-content: right;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
}

.table-container {
  max-height: 550px;
  overflow-y: auto;
  width: 100%;
  z-index: 0;
}
</style>