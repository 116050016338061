import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from "@/views/LoginView"
import MainView from "@/views/MainView";
import SettlementView from "@/views/SettlementView";
import PayInView from "@/views/PayInView";
import PayOutView from "@/views/PayOutView";
import ChargebackView from "@/views/ChargebackView";

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '',
    redirect: '/login'
  },
  {
    path:'/dashboard',
    name:'main',
    component: MainView,
  },
  {
    path:'/settlement',
    name:'settlement',
    component: SettlementView,
  },
  {
    path:'/transaction',
    name:'transaction',
    component: PayInView,
  },
  {
    path:'/disbursement',
    name:'disbursement',
    component: PayOutView,
  },
  {
    path:'/chargeback',
    name:'chargeback',
    component: ChargebackView,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
