<template>
  <div class="main-view">
    <SideMenu>
      <!-- card -->
      <el-card :gutter="20" style="height: 99%;" shadow="hover">
        <template #header>
          <div class="card-header">
            <span>Chargeback Report</span>
          </div>
        </template>

        <!-- form (reference number, payee name, orderStatus, creationTime) -->
        <el-form ref="listDataForm" :model="listData.filter">
          <el-row style="font-size: 12px;">
            <el-form-item>
              <el-input v-model="listData.filter.orderNo" placeholder="mchTxnId" style="width:180px" clearable/>
            </el-form-item>
            &nbsp&nbsp&nbsp

            <el-form-item>
              <el-input v-model="listData.filter.payee" placeholder="Name of Payee" style="width:180px" clearable/>
            </el-form-item>
            &nbsp&nbsp&nbsp

            <el-form-item>
              <el-date-picker v-model="listData.filter.createdAtStart" type="datetime" placeholder="Created Time At Start"
                              style="font-size: 12px; width: 160px" clearable></el-date-picker>
              &nbsp - &nbsp
              <el-date-picker v-model="listData.filter.createdAtEnd" type="datetime" placeholder="Created Time At End"
                              style="font-size: 12px; width: 160px" clearable></el-date-picker>
            </el-form-item>
            &nbsp&nbsp&nbsp

            <el-form-item>
              <el-date-picker v-model="listData.filter.updatedAtStart" type="datetime" placeholder="Updated Time At Start"
                              style="font-size: 12px; width: 160px" clearable></el-date-picker>
              &nbsp - &nbsp
              <el-date-picker v-model="listData.filter.updatedAtEnd" type="datetime" placeholder="Updated Time At End"
                              style="font-size: 12px; width: 160px" clearable></el-date-picker>
            </el-form-item>

            &nbsp &nbsp
            <el-button type="primary" style="background-color: #0071C6; border: 1px solid #cecccc" @click="search" :disabled="listData.loading">
              <el-icon><Search/></el-icon> Search
            </el-button>
            <el-button type="primary" style="background-color: #ffffff; color:#000000; border: 1px solid #cecccc" @click="reset" :disabled="listData.loading">
              <el-icon><Refresh /></el-icon> Reset
            </el-button>
<!--            <el-button type="primary" style="background-color: #ffffff; color:#000000; border: 1px solid #cecccc" @click="orderExportExcel" :disabled="listData.loading">-->
<!--              <el-icon><Download /></el-icon> Export-->
<!--            </el-button>-->
            <el-button type="primary"
                       style="background-color: #ffffff; color:#000000; border: 1px solid #cecccc"
                       @click="exportCSV" :disabled="listData.loading">
              <el-icon><Download/></el-icon> Export CSV
            </el-button>


          </el-row>

          <!-- export csv progress bar -->
          <div style="font-size: 12px; text-align: left" v-show="isShow">
            <el-progress :percentage="percent" style="width: 350px;"/>
            &nbsp;&nbsp;&nbsp;&nbsp;
            Current Download Count：
            <label>{{ count }}</label>
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <label>{{ csvTotal }}</label>
          </div>
          <br/>
        </el-form>

        <el-tabs v-model="activeTab" @tab-change="handleTabClick">
          <el-tab-pane label="Search Result" name="result">
            <!-- pay order list table -->
            <el-table ref="listTable" v-loading="listData.loading" :columns="listData.columns" :data="listData.list" border
                      class="table-container">
              <el-table-column prop="orderNo" label="mchTxnId" width="200"/>
              <el-table-column prop="sysOrderNo" label="refId" width="200"/>
              <el-table-column prop="amount" label="orderAmount" width="150"/>
              <el-table-column prop="actualAmount" label="actualAmount" width="150"/>
              <el-table-column prop="remark" label="Remark" width="150"/>
<!--              <el-table-column prop="payee" label="Payee Name" width="150"/>-->
              <el-table-column prop="merName" label="Merchant Name" width="150"/>
              <el-table-column prop="createTime" label="Created Time" :formatter="formatDateTime"/>
              <el-table-column prop="finishTime" label="Updated Time" :formatter="formatDateTime"/>
            </el-table>
          </el-tab-pane>

          <el-tab-pane label="This Month" name="thisMonth">
            <el-table ref="listTable" v-loading="listData.loading" :columns="listData.columns" :data="listData.list" border
                      class="table-container">
              <el-table-column prop="orderNo" label="mchTxnId" width="200"/>
              <el-table-column prop="sysOrderNo" label="refId" width="200"/>
              <el-table-column prop="amount" label="orderAmount" width="150"/>
              <el-table-column prop="actualAmount" label="actualAmount" width="150"/>
              <el-table-column prop="remark" label="Remark" width="150"/>
              <!--              <el-table-column prop="payee" label="Payee Name" width="150"/>-->
              <el-table-column prop="merName" label="Merchant Name" width="150"/>
              <el-table-column prop="createTime" label="Created Time" :formatter="formatDateTime"/>
              <el-table-column prop="finishTime" label="Updated Time" :formatter="formatDateTime"/>
            </el-table>
          </el-tab-pane>

          <el-tab-pane label="Last Month" name="lastMonth">
            <el-table ref="listTable" v-loading="listData.loading" :columns="listData.columns" :data="listData.list" border
                      class="table-container">
              <el-table-column prop="orderNo" label="mchTxnId" width="200"/>
              <el-table-column prop="sysOrderNo" label="refId" width="200"/>
              <el-table-column prop="amount" label="orderAmount" width="150"/>
              <el-table-column prop="actualAmount" label="actualAmount" width="150"/>
              <el-table-column prop="remark" label="Remark" width="150"/>
              <!--              <el-table-column prop="payee" label="Payee Name" width="150"/>-->
              <el-table-column prop="merName" label="Merchant Name" width="150"/>
              <el-table-column prop="createTime" label="Created Time" :formatter="formatDateTime"/>
              <el-table-column prop="finishTime" label="Updated Time" :formatter="formatDateTime"/>
            </el-table>
          </el-tab-pane>

        </el-tabs>

        <!-- pagination -->
        <div class="pagination-container">
          <el-pagination
              v-if="listData.total > 0"
              v-model:current-page="paginationData.currentPage"
              v-model:page-size="paginationData.pageSize"
              :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]"
              layout="total, prev, pager, next, sizes"
              :total="listData.total"
              @current-change="changePage"
              @size-change="changePageSize"
              style="font-size: 12px; position: absolute; bottom: 50px; "
          />
        </div>
      </el-card>
    </SideMenu>
  </div>
</template>

<script>
import SideMenu from "@/components/SideMenu";
import { mapActions } from 'vuex';
import {getChargebackList, chargebackExcelDown, chargebackCsvDown, chargebackCsvProgress} from "@/api/OrderApi";
import store from "@/store/UserStore";
import config from "@/config/index";

export default {
  name: "PayOrderView",
  components: {
    SideMenu
  },

  data () {
    return {
      activeTab: 'thisMonth', // Set the default active tab
      listData: {
        loading: false,
        filter: {
          page: 1,
          rows: 10,
          // pagination parameters
          firstOrderId: '',
          lastOrderId: '',
          firstPage: '',
          maxPage: '',
          maxRow: '',
          // Request parameters
          orderNo: '',
          // payee: '',
          sysOrderNo: '',
          orderStatus: '',
          createdAtStart: '',
          createdAtEnd: '',
          updatedAtStart: '',
          updatedAtEnd: '',
          logonToken: store.state.token,
        },
        list: [],
        columns: [
          {
            key: 'orderNo'
          },
          {
            key: 'sysOrderNo'
          },
          {
            key: 'amount'
          },
          {
            key: 'actualAmount'
          },
          {
            key: 'remark'
          },
          // {
          //   key: 'payee'
          // },
          {
            key: 'merName'
          },
          {
            key: 'createTime'
          },
          {
            key: 'finishTime'
          },
        ]
      },
      paginationData: {
        currentPage: 1,
        pageSize: 10,
      },
      screenWidth: null,
      intervalId: null,
      isShow: false,
      percent: 0,
      count: 0,
      total: 0,
      csvTotal: 0,
      tableHeight: 520,
      firstRow: '',
      excelDown: {
        loading: false,
        filter: {
          page: 1,
          rows: 10,
          // Request parameters
          orderNo: '',
          // payee: '',
          sysOrderNo: '',
          orderStatus: '',
          createdAtStart: '',
          createdAtEnd: '',
          updatedAtStart: '',
          updatedAtEnd: '',
          logonToken: store.state.token,
        },
      }
    }
  },

  methods:{
    ...mapActions([
      'userLogoutAction'
    ]),

    //export the record in csv file
    exportCSV() {
      this.excelDown.loading = true;
      this.listData.loading = true;
      this.excelDown.filter.orderNo = this.listData.filter.orderNo;
      this.excelDown.filter.sysOrderNo= this.excelDown.filter.sysOrderNo
      this.excelDown.filter.orderStatus= this.excelDown.filter.orderStatus
      this.excelDown.filter.createdAtStart = this.listData.filter.createdAtStart
      this.excelDown.filter.createdAtEnd = this.listData.filter.createdAtEnd
      this.excelDown.filter.updatedAtStart = this.listData.filter.updatedAtStart
      this.excelDown.filter.updatedAtEnd = this.listData.filter.updatedAtEnd

      let tempFilter = this.excelDown.filter;

      if (tempFilter.createdAtStart) {
        const parsedStartTime = new Date(tempFilter.createdAtStart);
        tempFilter.createdAtStart = this.formatDateToAPIFormat(parsedStartTime)
      }
      if (tempFilter.createdAtEnd) {
        const parsedEndTime = new Date(tempFilter.createdAtEnd);
        tempFilter.createdAtEnd = this.formatDateToAPIFormat(parsedEndTime)
      }
      if (tempFilter.updatedAtStart) {
        const parsedStartTime = new Date(tempFilter.updatedAtStart);
        tempFilter.updatedAtStart = this.formatDateToAPIFormat(parsedStartTime)
      }
      if (tempFilter.updatedAtEnd) {
        const parsedEndTime = new Date(tempFilter.updatedAtEnd);
        tempFilter.updatedAtEnd = this.formatDateToAPIFormat(parsedEndTime)
      }

      //generate csvToken for export and store it
      chargebackCsvDown(tempFilter).then(async res => {
        if (res.data != null) {
          let csvToken = res.data
          this.dataRefresh(csvToken)
        }
      }).catch((e) => {
        // console.log("exportCsv, e:" + e)
      })
    },


    //check csv download progress with csvToken generated in payDFCsvDown
    dataRefresh(csvToken) {
      // The timer is in progress, exit the function
      if (this.intervalId != null) {
        return
      }
      // The timer is empty and operates
      this.intervalId = setInterval(() => {
        let params = {logonToken: this.listData.filter.logonToken, csvToken: csvToken}
        chargebackCsvProgress(params).then(async res => {
          this.isShow = true // show progress bar
          if (res.code == 0) {
            let data = res.data
            if (data != null) {
              // if still downloading
              if (this.percent < 100) {
                this.percent = data.percent
                this.csvTotal = data.total
                this.count = data.count
              } else { // download completed
                this.clear()
                this.isShow = false // hide progress bar
                this.percent = data.percent
                //direct to this urls in new tab
                // let urls= cfg.baseUrl.api + "/file/" + csvToken + `.csv`
                let urls = `${config.baseUrl.static}/` + csvToken + `.csv`
                window.open(urls, '_blank')
              }
            }
            // this.clear()
          }
        }).catch((e) => {
          // console.log("e:" + e)
        })
      }, 1000) // check the progress every 1 second
    },

    clear() {
      clearInterval(this.intervalId) // Clear timer
      this.intervalId = null // set as null
      this.search()
    },

    orderExportExcel(e) {
      this.excelDown.filter.createdAtStart = this.listData.filter.createdAtStart
      this.excelDown.filter.createdAtEnd = this.listData.filter.createdAtEnd
      this.excelDown.filter.updatedAtStart = this.listData.filter.updatedAtStart
      this.excelDown.filter.updatedAtEnd = this.listData.filter.updatedAtEnd

      this.excelDown.loading = true;
      this.listData.loading = true;
      this.excelDown.filter.orderNo = this.listData.filter.orderNo;
      this.excelDown.filter.sysOrderNo= this.excelDown.filter.sysOrderNo
      this.excelDown.filter.orderStatus= this.excelDown.filter.orderStatus
      this.excelDown.filter.createdAtStart= this.excelDown.filter.createdAtStart
      this.excelDown.filter.createdAtEnd= this.excelDown.filter.createdAtEnd
      this.excelDown.filter.updatedAtStart= this.excelDown.filter.updatedAtStart
      this.excelDown.filter.updatedAtEnd= this.excelDown.filter.updatedAtEnd

      let tempFilter = this.excelDown.filter;

      // 调用导出方法
      chargebackExcelDown(tempFilter)
          .then(res => {
            this.excelDown.loading = false;
            this.listData.loading = false;
            const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
            let filename = new Date().getTime() + '.xls';
            const elink = document.createElement('a');
            elink.download = filename;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          })
          .catch(err => {
            this.excelDown.loading = false;
            this.$message.error('Export failed');
          });
    },

    handleTabClick(tab) {
      let tabName = this.activeTab
      if( tabName === 'result'){
        this.search();
      }
      // 根据点击的tab设置参数的值
      else if (tabName === 'thisMonth') {
        const today = new Date();
        const startOfMonth = new Date(today);
        const endOfMonth = new Date(today);

        startOfMonth.setDate(1); // Set to the first day of the current month
        endOfMonth.setMonth(endOfMonth.getMonth() + 1, 0); // Set to the last day of the current month

        const formattedStartOfMonth = this.formatDateToAPIFormat2(startOfMonth);
        const formattedEndOfMonth = this.formatDateToAPIFormat2(endOfMonth);

        this.listData.filter.createdAtStart = formattedStartOfMonth + " 00:00:00";
        this.listData.filter.createdAtEnd = formattedEndOfMonth + " 23:59:59";

        this.loadList();
      } else if (tabName === 'lastMonth') {
        const today = new Date();
        const startOfLastMonth = new Date(today);
        const endOfLastMonth = new Date(today);

        startOfLastMonth.setMonth(startOfLastMonth.getMonth() - 1, 1); // Set to the first day of the previous month
        endOfLastMonth.setMonth(endOfLastMonth.getMonth(), 0); // Set to the last day of the previous month

        const formattedStartOfLastMonth = this.formatDateToAPIFormat2(startOfLastMonth);
        const formattedEndOfLastMonth = this.formatDateToAPIFormat2(endOfLastMonth);

        this.listData.filter.createdAtStart = formattedStartOfLastMonth + " 00:00:00";
        this.listData.filter.createdAtEnd = formattedEndOfLastMonth + " 23:59:59";

        this.loadList();
      }
    },


    loadList() {
      this.listData.loading = true
      let tempFilter = {
        ...this.listData.filter, // 保留原有的过滤条件
        page: this.paginationData.currentPage,
        rows: this.paginationData.pageSize,
      };

      //change the startTime and endTime format
      if (tempFilter.createdAtStart) {
        const parsedStartTime = new Date(tempFilter.createdAtStart);
        tempFilter.createdAtStart = this.formatDateToAPIFormat(parsedStartTime)
      }
      if (tempFilter.createdAtEnd) {
        const parsedEndTime = new Date(tempFilter.createdAtEnd);
        tempFilter.createdAtEnd = this.formatDateToAPIFormat(parsedEndTime)
      }
      if (tempFilter.updatedAtStart) {
        const parsedStartTime = new Date(tempFilter.updatedAtStart);
        tempFilter.updatedAtStart = this.formatDateToAPIFormat(parsedStartTime)
      }
      if (tempFilter.updatedAtEnd) {
        const parsedEndTime = new Date(tempFilter.updatedAtEnd);
        tempFilter.updatedAtEnd = this.formatDateToAPIFormat(parsedEndTime)
      }

      // send data to retrieve user records
      getChargebackList(tempFilter).then(res => {
        if (res.code == 0) { //operate success
          this.listData.loading = false
          //Data{} in API return
          let respondData = res.data

          //pagination function
          // Check if the response data contains the 'rows' property and 'rows' is an array
          if (respondData && Array.isArray(respondData.rows)) {
            // If 'rows' property exists and is an array, update table data and total count
            this.listData.list = respondData.rows;
            this.listData.total = respondData.total;

            // Calculate the page size, default is 10
            const pageSize = this.paginationData.pageSize;

            // Calculate the current page, default is 1
            const currentPage = this.paginationData.currentPage;

            // Calculate the start and end index of data on the current page
            const startIndex = (this.listData.filter.page - 1) * this.listData.filter.rows;
            const endIndex = startIndex + this.listData.filter.rows;

            // Split data into the current page's data
            // Use the slice method to extract the data of the current page from the tempData.rows array
            this.listData.list = respondData.rows.slice(startIndex, endIndex);

            this.getData(respondData)
            this.listData.loading = false
          }
          else {
            // If 'rows' property does not exist or is not an array, clear the table data and set the total count to 0
            this.listData.list = [];
            this.listData.total = 0;
          }

        } else if(res.code == 18){
          this.userLogoutAction().then(() => {
            this.$router.push('/login');
          })
          this.$message.error(res.msg);
        } else { //operate failed
          this.listData.loading = false
          this.$message.error(res.msg)
        }
      }).catch((e) => {
        // console.log(e);
      })
    },

    getData(data) {
      this.totalCount = data.total
      if (data.rows.length > 0) {
        // get the first row id
        this.listData.filter.firstOrderId = data.rows[0].id
        // get the last row id
        this.listData.filter.lastOrderId = data.rows[data.rows.length - 1].id
        // current page
        this.listData.filter.firstPage = this.paginationData.currentPage
        // current page's rows
        this.firstRow = this.paginationData.pageSize
      }

      // Assign serial numbers to each row of data
      const startIndex = (this.listData.filter.page - 1) * this.listData.filter.rows;
      this.listData.list.forEach((row, index) => {
        row.serialNo = startIndex + index + 1;
      });
    },


    // let Create Time and Finish Time return in yyyy-mm-dd hh:mm:ss format
    // formatDateTime(row, column, cellValue) {
    //   if (cellValue) {
    //     const date = new Date(cellValue);
    //     const year = date.getFullYear();
    //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
    //     const day = date.getDate().toString().padStart(2, '0');
    //     const hours = date.getHours().toString().padStart(2, '0');
    //     const minutes = date.getMinutes().toString().padStart(2, '0');
    //     const seconds = date.getSeconds().toString().padStart(2, '0');
    //
    //     return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    //   } else {
    //     return '';
    //   }
    // },

    // let Create Time and Finish Time return in ff mm yyyy format
    formatDateTime(row, column) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const dateString = row[column.property];
      const date = new Date(dateString);
      if (isNaN(date)) {
        return '';
      }

      const day = String(date.getDate()).padStart(2, '0');
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      // const hours = String(date.getHours()).padStart(2, '0');
      // const minutes = String(date.getMinutes()).padStart(2, '0');
      // const seconds = String(date.getSeconds()).padStart(2, '0');

      // return `${day}${month}${year} ${hours}:${minutes}:${seconds}`;
      return `${day} ${month} ${year} `;
    },

    // Returns the date in 'yyyy-MM-dd hh:mm:ss' format
    formatDateToAPIFormat(date) {
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    },

    // Returns the date in 'yyyy-MM-dd' format
    formatDateToAPIFormat2(date) {
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} `;
    },

    // pagination change page
    changePage(page) {
      let totalCount = this.totalCount
      this.listData.filter.maxPage = Math.ceil(totalCount / this.paginationData.pageSize)
      if (totalCount > page) {
        this.listData.filter.maxRow = totalCount % this.paginationData.pageSize
      }

      if(this.listData.filter.maxRow == 0)
      {
        this.listData.filter.maxRow = this.paginationData.pageSize
      }

      this.paginationData.currentPage = page;

      this.loadList();
    },

    // Toggle the number of entries per page
    changePageSize(pageSize) {
      // When the number of pages is modified, jump to the first page
      this.listData.filter.rows = pageSize
      this.paginationData.pageSize = pageSize;
      this.paginationData.currentPage = 1
      this.listData.filter.firstPage = ''

      this.loadList()
    },

    //Search for data
    search() {
      this.listData.filter.page = 1
      this.paginationData.currentPage = 1
      this.listData.filter.firstOrderId = ''
      this.listData.filter.lastOrderId = ''
      this.listData.filter.firstPage = ''
      this.firstRow = ''
      this.activeTab = 'result'
      this.loadList()
    },


    //Clear the entered data
    reset() {
      this.listData.filter.rows = 10
      this.listData.filter.page = 1
      this.paginationData.currentPage = 1
      this.paginationData.pageSize = 10
      this.listData.filter.firstOrderId = ''
      this.listData.filter.lastOrderId = ''
      this.listData.filter.firstPage = ''
      this.firstRow = ''
      this.activeTab = 'result'
      this.listData.filter.orderNo = ''
      // this.listData.filter.payee = ''
      this.listData.filter.orderStatus = ''
      this.listData.filter.createdAtStart = ''
      this.listData.filter.createdAtEnd = ''
      this.listData.filter.updatedAtStart = ''
      this.listData.filter.updatedAtEnd = ''
      this.loadList()
    },


  },

  created(){

    const today = new Date();
    const startOfMonth = new Date(today);
    const endOfMonth = new Date(today);

    startOfMonth.setDate(1); // Set to the first day of the current month
    endOfMonth.setMonth(endOfMonth.getMonth() + 1, 0); // Set to the last day of the current month

    const formattedStartOfMonth = this.formatDateToAPIFormat2(startOfMonth);
    const formattedEndOfMonth = this.formatDateToAPIFormat2(endOfMonth);

    this.listData.filter.createdAtStart = formattedStartOfMonth + " 00:00:00";
    this.listData.filter.createdAtEnd = formattedEndOfMonth + " 23:59:59";

    this.loadList()
  }
}
</script>

<style scoped>
.table-container {
  max-height: 550px;
  overflow-y: auto;
  width: 100%;
  z-index: 0;
}

.el-button {
  background-color: #2389da;
  color: white;
  border: none;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.el-button:hover {
  border: #0e1c26;
  background-color: #5abcd8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

body,
.el-table th,
.el-table td,
.el-input {
  font-size: 12px;
}

.card-header {
  text-align: center;
  font-weight: bold;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>